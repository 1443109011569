// Third-party
import { useRef, useEffect } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

// First-party
import { toClassName, createComponent } from '@lib/util/templateHelpers';
import { useResize } from '@lib/hooks/resize';
import shared from '@lib/components';
import { clamp } from '@lib/util/math';
const { Icon, Flex, Button } = shared;

// Local
import styles from './index.module.scss';
import anime from 'animejs';
import { isMobileBreakpoint } from '@lib/util/mobile';

const CarouselScrollTo = {
  START: 'start',
  NEXT: 'next',
  PREVIOUS: 'previous',
  RESET: 'reset'
};

export default createComponent('Carousel', { styles }, function Carousel ({ className }, props) {
  // Elements
  const carouselRef = useRef(null);
  const carouselInnerRef = useRef(null);
  const buttonLeftRef = useRef(null);
  const buttonRightRef = useRef(null);

  // Carousel state
  const offsetScrollRef = useRef(0);
  const currentIndexRef = useRef(-1);
  const disableScrollWhileLoadingRef = useRef(false);
  const disableScrollForDelayRef = useRef(false);
  const disableScrollForDelayDebounceRef = useRef(undefined);

  // Animation state
  const animationRef = useRef(undefined);
  const transitioningGamesRef = useRef(false);

  // Fetch and queue state
  const fetchingGamesRef = useRef(false);
  const requestQueueRef = useRef([]);
  const nextGamesRef = useRef([]);
  const applyNextGamesDebounceRef = useRef(undefined);

  const toggleButtonsLoading = (isLoading) => {
    disableScrollWhileLoadingRef.current = isLoading;

    const buttonLeftEl = buttonLeftRef.current;
    const buttonRightEl = buttonRightRef.current;

    if (!buttonLeftEl || !buttonRightEl) return; // component has been unmounted
    
    const method = isLoading
      ? 'add'
      : 'remove';

    buttonLeftEl
      .querySelector('.Button')
      .classList[method]('Button--loading');

    buttonRightEl
      .querySelector('.Button')
      .classList[method]('Button--loading');
  };

  const setNextGames = () => {
    clearTimeout(applyNextGamesDebounceRef.current);

    applyNextGamesDebounceRef.current = setTimeout(() => {
      if (transitioningGamesRef.current) return;

      props.setNextGames(nextGamesRef.current);
      nextGamesRef.current = [];

      toggleButtonsLoading(false);
    }, 200);
  };

  const queueFetchRequest = () => {
    // number of games to load based on width of carousel
    const parentEl = carouselRef.current;
    const { width } = parentEl.getBoundingClientRect();

    let nextLimit, limit
    const gameTileWidth = process.env.APP_CONFIG.GAME_TILE_WIDTH + process.env.APP_CONFIG.GAME_TILE_GRID_GAP;
    const featuredTileWidth = process.env.APP_CONFIG.GAME_TILE_WIDTH * 2 + process.env.APP_CONFIG.GAME_TILE_GRID_GAP * 2;
    
    if (props.featured) {
      nextLimit = (width / (gameTileWidth + featuredTileWidth)) * 5;
      nextLimit = Math.ceil(nextLimit) * 2;
      limit = clamp(nextLimit, 0, process.env.APP_CONFIG.MAX_CAROUSEL_GAME_LIMIT);
    } else {
      nextLimit = width / gameTileWidth;
      nextLimit = Math.ceil(nextLimit) * 2;
      limit = clamp(nextLimit, 0, process.env.APP_CONFIG.MAX_CAROUSEL_GAME_LIMIT);
    }

    if (currentIndexRef.current === -1) {
      currentIndexRef.current = carouselInnerRef.current.querySelectorAll('.IoGameTile').length
    }

    requestQueueRef.current.push({ index: currentIndexRef.current, limit });
    currentIndexRef.current += limit;

    if (!fetchingGamesRef.current) runFetchQueue();
  };

  const runFetchQueue = async () => {
    fetchingGamesRef.current = true;

    const queue = requestQueueRef.current;

    // Apply the fetched games if no transitions are playing
    if (!transitioningGamesRef.current && nextGamesRef.current.length) {
      setNextGames();
    }

    // Queue has finished running
    if (!queue.length) {
      fetchingGamesRef.current = false;
      return;
    }

    const requestOpts = queue.shift();
    let games = await props.fetchMoreGames(requestOpts.limit, requestOpts.index);

    let i = 0;
    const responseLength = games.length;
    while (games.length < requestOpts.limit) {
      games.push(games[i % responseLength]);
      i++;
    }

    games = games.filter((game) => game !== null && game !== undefined);

    if (games.length !== 0) {
      nextGamesRef.current.push.apply(nextGamesRef.current, games);
    }

    if (games.length < requestOpts.limit) {
      currentIndexRef.current = requestOpts.limit;
      requestQueueRef.current = [
        { index: 0, limit: requestOpts.limit }
      ];
    }

    runFetchQueue();
  };

  const scroll = (direction) => {
    // Hacky garbage because of game tile zoom effect
    const floatingGameTiles = Array.from(document.querySelectorAll('.IoGameTile--floating'));
    floatingGameTiles.forEach((el) => el.parentElement && el.parentElement.removeChild(el));

    // Don't allow spam clicking of the buttons when outpacing the carousel
    if (disableScrollWhileLoadingRef.current) return;
    if (disableScrollForDelayRef.current) return;

    disableScrollForDelayRef.current = true;
    
    clearTimeout(disableScrollForDelayDebounceRef.current);
    disableScrollForDelayDebounceRef.current = setTimeout(() => {
      disableScrollForDelayRef.current = false;
    }, process.env.APP_CONFIG.CAROUSEL_BUTTON_CLICK_SPAM_INTERVAL);

    const el = carouselInnerRef.current;
    const parentEl = carouselRef.current;
    const buttonLeftEl = buttonLeftRef.current;
    const buttonRightEl = buttonRightRef.current;

    if (!buttonLeftEl || !buttonRightEl) return; // component has been unmounted

    let width
    if (isMobileBreakpoint()) {
      const brect = parentEl.querySelector('.GameTile').getBoundingClientRect();
      width = brect.width + process.env.APP_CONFIG.GAME_TILE_GRID_GAP;
      if (brect.width < window.innerWidth / 2) width *= 2; // if 2x2 instead of 1x1
    } else {
      const brect = parentEl.getBoundingClientRect();
      width = brect.width;
    }

    // Fetch more games
    if (direction === CarouselScrollTo.NEXT) {
      queueFetchRequest();
    }

    // Calculate next position
    if (direction === CarouselScrollTo.START || direction === CarouselScrollTo.RESET) {
      offsetScrollRef.current = 0;
    } else {
      // If scrolling from right to left, we need to subtract the width of the container
      if (direction === CarouselScrollTo.NEXT) {
        offsetScrollRef.current -= width;
      } else {
        offsetScrollRef.current += width;
      }
    }

    // Hide left button if at start of carousel
    if (offsetScrollRef.current === 0) {
      buttonLeftEl.classList.add('Carousel__Button--hidden');
      buttonRightEl.classList.remove('Carousel__Button--hidden');
    } else {
      buttonLeftEl.classList.remove('Carousel__Button--hidden');
      buttonRightEl.classList.remove('Carousel__Button--hidden');
    }

    // Prevent outpacing
    // if (direction === CarouselScrollTo.NEXT) {
    //   const parentEl = carouselRef.current;
    //   const { width: innerWidth } = parentEl.getBoundingClientRect();
    //   const innerOffset = Math.abs(offsetScrollRef.current);

    //   const isOutpacing = innerWidth <= innerOffset + width;

    //   if (isOutpacing) {
    //     toggleButtonsLoading(true);
    //     queueFetchRequest();
    //   }
    // }

    // Transition carousel

    // Remove any currently playing transition
    if (animationRef.current) {
      animationRef.current.remove()
    }
    
    if (direction === CarouselScrollTo.RESET) {
      buttonLeftEl.classList.add('Carousel__Button--hidden')
      buttonRightEl.classList.remove('Carousel__Button--hidden')
      toggleButtonsLoading(false);

      animationRef.current = anime({
        targets: el,
        easing: 'linear',
        duration: 0,
        translateX: `${0}px`
      });
    } else {
      (async () => {
        transitioningGamesRef.current = true;
        el.style.pointerEvents = 'none';

        // Wait until the transition has completed...
        animationRef.current = anime({
          ...props.transition,
          targets: el,
          translateX: `${offsetScrollRef.current}px`
        });
        await animationRef.current.finished;

        transitioningGamesRef.current = false;
        el.style.pointerEvents = 'auto';

        // Cached games from fetch call ready to be added
        if (nextGamesRef.current.length) {
          setNextGames();
        }
      })();
    }
  };

  const transitionInAnimationRef = useRef(undefined);
  useEffect(() => {
    const parentEl = carouselRef.current;

    let gameTileEls = Array.from(parentEl.querySelectorAll('.IoGameTile:not(.--finished)'));
    if (!gameTileEls.length) {
      return;
    }

    // Add finished modifier so we know not to transition them again
    gameTileEls.forEach((el) => el.classList.add('--finished'));

    // Remove existing animations to avoid animation glitches
    if (transitionInAnimationRef.current) {
      anime.remove(gameTileEls);
    }

    // Don't animate things that are off-screen
    const { left } = gameTileEls[0].getBoundingClientRect();
    if (left > window.innerWidth) {
      return;
    } else {
      gameTileEls = gameTileEls.filter((el) => {
        const { left } = el.getBoundingClientRect();
        return left <= window.innerWidth;
      });
    }

    // Do the transition
    transitionInAnimationRef.current = anime({
      targets: gameTileEls,
      opacity: {
        value: [0, 1],
        easing: 'easeInOutCubic',
        duration: 200
      },
      scale: [0.65, 1],
      delay: anime.stagger(20)
    });
  }, [props.children]);

  useResize(() => {
    scroll(CarouselScrollTo.RESET);
  }, true);

  const next = () => {
    scroll(CarouselScrollTo.NEXT);
  };

  const prev = () => {
    scroll(CarouselScrollTo.PREVIOUS);
  };

  return (
    <div ref={carouselRef} className={className}>
      <div ref={carouselInnerRef} className='Carousel__Inner'>
        {props.children}
      </div>
      <div ref={buttonLeftRef} className={toClassName('Carousel__Button', '&--left', '&--hidden')} onClick={prev}>
        <Flex center>
          <Button primary ariaLabel='Previous Games'><Icon.FA icon={faArrowLeft} /></Button>
        </Flex>
      </div>
      <div ref={buttonRightRef} className={toClassName('Carousel__Button', '&--right')} onClick={next}>
        <Flex center>
          <Button primary ariaLabel='Next Games'><Icon.FA icon={faArrowRight} /></Button>
        </Flex>
      </div>
    </div>
  );
});
